







































import {Component, Prop, PropSync, Ref, Vue, Watch} from "vue-property-decorator"
import Product from "@/models/Product";
import ProductCategory from "@/models/ProductCategory"
import {getModule} from "vuex-module-decorators"
import Video from "@/models/Video";
import VideoService from "@/services/VideoService";
import FilePickerComponent from "@/components/FilePickerComponent.vue"
import LangModule from "@/store/LangModule";
import ProductCategoryService from "@/services/ProductCategoryService"
import Dialog from "@/models/vue/Dialog"
import ProductService from "@/services/ProductService"
import DialogModule from "@/store/DialogModule"
import SnackbarModule from "@/store/SnackbarModule"

@Component
export default class ProductCategoriesTab extends Vue {

  @Prop() readonly product!: Product
	lang: any = getModule(LangModule).lang
	model: ProductCategory[] = []

  loading: boolean = false
  productCategories: ProductCategory[] = []

  page: number = 1
	pageCount: number = 0
  search: string = ""
  headersCategories = [
		{ text: this.lang.category, value: "name", width: "auto", align: "center" },
		{ text: this.lang.code, value: "code", width: "auto", align: "center" },
		{ text: "ID", value: "id", width: "auto", align: "center" },
		{ text: this.lang.actions, value: "actions", width: "auto", align: "center" }
	]


	log(category: [ProductCategory]) {
		if (this.model.length == 0) {
			this.model = [category[0]]
		} else if (this.model.length == 1) {
			// @ts-ignore
			if (category.length == 0) {
				this.model = []
			} else {
				// @ts-ignore
				this.model = [category[category.length - 1]]
			}
		}
	}

  created() {
    this.refresh()
  }

  refresh() {
    this.$emit("refresh")
    ProductCategoryService.getProductCategoriesTest(this, this.productCategories)
  }

  addProductCategory() {
		if (this.model.length == 0) {
		  getModule(SnackbarModule).makeToast("Debes seleccionar una categoría.")
		} else {
      getModule(DialogModule).showDialog(new Dialog(
        "Aviso",
        "Esta categoría será añadida a este producto, ¿Esta seguro?",
        () => ProductService.relateProductCategory(this, this.product.id!, this.model[0].id!)
      ))
    }
	}

	removeProductCategory(productCategory: ProductCategory) {
		getModule(DialogModule).showDialog(new Dialog(
			"Aviso",
			"El producto será excluido de esta categoría, ¿Esta seguro?",
			() => ProductService.unrelateProductCategory(this, this.product.id!, productCategory.id!)
		))
	}


}
