import {JsonConverter, JsonCustomConvert, JsonObject, JsonProperty} from "json2typescript";
import {RoleType} from "@/models/Role";

export enum EntityType {
    PRODUCT = "PRODUCT",
}

export enum LanguageType {
    SPANISH = "SPANISH",
    ENGLISH = "ENGLISH",
    FRENCH = "FRENCH",
    ITALIAN = "ITALIAN",
    PORTUGUESE = "PORTUGUESE"
}

@JsonConverter
class LanguageTypeConverter implements JsonCustomConvert<LanguageType> {

    deserialize(data: any): LanguageType {
        return (<any>LanguageType)[data]
    }
    serialize(data: LanguageType): any {
        return data.toString()
    }

}

@JsonConverter
class EntityConverter implements JsonCustomConvert<EntityType> {

    deserialize(data: any): EntityType {
        return (<any>EntityType)[data]
    }
    serialize(data: EntityType): any {
        return data.toString()
    }

}

@JsonObject("Translation")
export default class Translation {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("entity", String, true)
    entity?: EntityType = undefined
    @JsonProperty("language", LanguageTypeConverter, true)
    language?: LanguageType = undefined
    @JsonProperty("entityId", Number, true)
    entityId?: number = undefined
    @JsonProperty("entityField", String, true)
    entityField?: string = undefined
    @JsonProperty("translate", String, true)
    translate?: string = undefined
}
