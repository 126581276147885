import {Vue} from "vue-property-decorator";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import SnackbarModule from "@/store/SnackbarModule";

export default class ProductBarcodeService {


    static postProductBarcode(component: Vue, productId: number, barcode: string) {
        (<any>component).loading = true
        let formData: FormData = new FormData()
        formData.set("barcode", barcode)
        component.axios.post(ConstantTool.BASE_URL + "/api/products/" + productId + "/product-barcodes/",
            formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            .then(() => (<any>component).refresh())
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo añadir el código de barras"))
            .finally(() => (<any>component).loading = false)
    }

    static deleteProductBarcode(component: Vue, productBarcodeId: number) {
        (<any>component).loading = true
        component.axios.delete(ConstantTool.BASE_URL + "/api/product-barcodes/" + productBarcodeId, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(() => (<any>component).refresh())
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo eliminar el código de barras"))
            .finally(() => (<any>component).loading = false)
    }

}
