













































































































import {Component, Ref, Vue, Watch} from "vue-property-decorator"
import Product from "@/models/Product"
import ProductCategory from "@/models/ProductCategory"
import ProductCategoryService from "@/services/ProductCategoryService"
import ProductService from "@/services/ProductService"
import {getModule} from "vuex-module-decorators"
import DialogModule from "@/store/DialogModule"
import Dialog from "@/models/vue/Dialog"
import Video from "@/models/Video"
import VideoService from "@/services/VideoService"
import FilePickerComponent from "@/components/FilePickerComponent.vue"
import RouterTool from "@/services/tool/RouterTool"
import ProductFeatureType from "@/models/ProductFeatureType"
import ProductFeatureTypeService from "@/services/ProductFeatureTypeService"
import ProductFeatureService from "@/services/ProductFeatureService"
import ProductFeature from "@/models/ProductFeature"
import ProductBarcodeService from "@/services/ProductBarcodeService"
import ProductBarcode from "@/models/ProductBarcode"
import LangModule from "@/store/LangModule"
import ProductCategoryTool from "@/services/tool/ProductCategoryTool"
import BreadcrumbsModule from "@/store/BreadcrumbsModule"
import SnackbarModule from "@/store/SnackbarModule"
import ProductCategoriesTab from "@/components/tabs/product/ProductCategoriesTab.vue"
import ProductLangTab from "@/components/tabs/product/ProductLangTab.vue";
import ProductDocumentTab from "@/components/tabs/product/ProductDocumentTab.vue";
import Handler from "@/handlers/Handler";
import {MultipleItem, SingleItem} from "@/handlers/interfaces/ContentUI";
import ProductEditTab from "@/components/tabs/product/ProductEditTab.vue";

@Component({components: {ProductEditTab, FilePickerComponent, ProductCategoriesTab, ProductLangTab, ProductDocumentTab }})
export default class ProductView extends Vue {
	lang: any = getModule(LangModule).lang
	@Ref() readonly form!: HTMLFormElement
	@Ref() readonly formDocuments!: HTMLFormElement
	@Ref() readonly formBarcodes!: HTMLFormElement
	@Ref() readonly formFeatures!: HTMLFormElement
	product: Product = new Product()
	product2: SingleItem<Product> = { item: new Product() }
	loading: boolean = false
	productCategories: ProductCategory[] = []
	orderedProductCategories: ProductCategory[] = []
	selectedCategory: ProductCategory = {}
	vatPrice: number = 0
	priceTotal: number = 0
	videos: Video[] = []
	image: File | null = null
	document: File | null = null
	productFeatureTypes: ProductFeatureType[] = []
	productFeatureType: ProductFeatureType = new ProductFeatureType()
	value: string = ""
	page: number = 1
	pageCount: number = 0
	search: string = ""
	headers = [
		{ text: "Id", value: "id", width: "120px" },
		{ text: "Nombre", value: "productFeatureType.name", width: "200px" },
		{ text: "Valor", value: "value", width: "200px" },
		{ text: "Magnitud", value: "productFeatureType.magnitude", width: "200px" },
		{ text: "Acciones", value: "actions", width: "200px" },
	]
	headersRates = [
		{ text: this.lang.number, value: "connectorId", width: "auto", align: "center" },
		{ text: this.lang.price, value: "price", width: "auto", align: "center" },
	]
	headersCategories = [
		{ text: this.lang.category, value: "name", width: "auto", align: "center" },
		{ text: this.lang.code, value: "code", width: "auto", align: "center" },
		{ text: "ID", value: "id", width: "auto", align: "center" },
		{ text: this.lang.actions, value: "actions", width: "auto", align: "center" }
	]
	barcode: string = ""
	brand: string = "Ninguna"
	videos2: MultipleItem<Video> = { items: [], totalItems: 0 }

	tab: number = 0
	tabs: string[] = ["/documents", "/barcodes", "/rates", "/categories", "/langs"]

	created() {
		this.tab = RouterTool.configTabs(this, this.tabs)
		this.refresh()
	}

	async refresh() {

		await Handler.getItem(this, this.product2, () => ProductService.getPublicProduct(Number(this.$route.params.productId)))

		this.barcode = ""
		this.value = ""
		this.productFeatureType = new ProductFeatureType()
		if (this.formBarcodes) this.formBarcodes.resetValidation()
		if (this.formFeatures) this.formFeatures.resetValidation()
		ProductService.getProduct(this, Number(this.$route.params.productId))
		ProductCategoryService.getProductCategories(this, this.productCategories)

		ProductFeatureTypeService.getProductFeatureTypes(this, this.productFeatureTypes)

	}

	calculatePrices() {
		// sacar base imponible 1210 / 1,21 = 1000
		let vat = this.product.vatPercentage! / 100 + 1 // 1.21
		this.product.price = Number((this.priceTotal / vat).toFixed(2))
		this.vatPrice = Number((this.priceTotal - this.product.price).toFixed(2))
	}

	async updateProductDocuments() {
		if (this.formDocuments.validate()) {
			getModule(DialogModule).showDialog(new Dialog(
				this.lang.warning, this.lang.updateDocumentsConfirmation, async () => {
					await ProductService.patchProductDocuments(this, Number(this.$route.params.productId), this.image!, this.document!)
				}
			))
		}
	}

	clearProductDocument() {
		getModule(DialogModule).showDialog(new Dialog(
			 this.lang.warning, this.lang.deleteProductDocumentsConfirmation, async () => {
				 await ProductService.clearProductDocuments(this, Number(this.$route.params.productId))
				 this.product.document = undefined
				 this.document = null
				 // @ts-ignore
				 this.$refs.filePicker.clear()
			 }
		))
	}


	addProductBarcode() {
		if (this.formBarcodes.validate()) {
			getModule(DialogModule).showDialog(new Dialog(
				this.lang.warning, this.lang.addBarcodeConfirmation,
				() => ProductBarcodeService.postProductBarcode(this, Number(this.$route.params.productId), this.barcode)
			))
		}
	}

	removeProductBarcode(productBarcode: ProductBarcode) {
		getModule(DialogModule).showDialog(new Dialog(
			this.lang.warning, this.lang.deleteBarcodeConfirmation,
			() => ProductBarcodeService.deleteProductBarcode(this, productBarcode.id!)
		))
	}

	addProductFeature() {
		if (this.formFeatures.validate()) {
			getModule(DialogModule).showDialog(new Dialog(
				this.lang.warning, this.lang.addFeatureConfirmation,
				() => ProductFeatureService.postProductFeature(this, Number(this.$route.params.productId), this.productFeatureType.id!, this.value)
			))
		}
	}

	removeProductFeature(productFeature: ProductFeature) {
		getModule(DialogModule).showDialog(new Dialog(
			this.lang.warning, this.lang.deleteFeatureConfirmation,
			() => ProductFeatureService.deleteProductFeature(this, productFeature.id!)
		))
	}

	selectCategory(productCategory: ProductCategory[]) {
		console.log(productCategory)
		this.selectedCategory = productCategory[0]
	}

	addProductCategory() {
		if (this.selectedCategory.id == undefined) {
			getModule(SnackbarModule).makeToast(this.lang.mustChooseCategory)
		}
		getModule(DialogModule).showDialog(new Dialog(
			this.lang.warning, this.lang.addCategoryToProductConfirmation,
			() => ProductService.relateProductCategory(this, this.product.id!, this.selectedCategory.id!)
		))
	}

	removeProductCategory(productCategory: ProductCategory) {
		getModule(DialogModule).showDialog(new Dialog(
			this.lang.warning, this.lang.excludeCategoryFromProductConfirmation,
			() => ProductService.unrelateProductCategory(this, this.product.id!, productCategory.id!)
		))
	}

	@Watch("tab")
	watchTab() {
		RouterTool.watchTabs(this, "/products/" + Number(this.$route.params.productId), this.tab, this.tabs)
	}

	@Watch("priceTotal")
	watchPriceTotal() {
		this.calculatePrices()
	}

	@Watch("product.price")
	watchProductPrice() {
		let vat = this.product.vatPercentage! / 100 + 1 // 1.21
		this.priceTotal = Number((this.product.price! * vat).toFixed(2))
		this.vatPrice = Number((this.priceTotal - this.product.price!).toFixed(2))
	}

	@Watch("product.vatPercentage")
	watchProductVatPercentage() {
		this.calculatePrices()
	}

}
