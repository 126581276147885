

























































import {Component, Vue, Watch} from "vue-property-decorator"
import Product from "@/models/Product"
import {getModule} from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import ProductService from "@/services/ProductService"
import Translation, {EntityType, LanguageType} from "@/models/Translation"
import TranslationService from "@/services/TranslationService";
import StringTool from "@/services/tool/StringTool";

@Component
export default class ProductLangTab extends Vue {

	get languages() { return this.langModule.languages }
	product: Product = new Product()
	langModule: any = getModule(LangModule)
	lang: any = getModule(LangModule).lang
	loading: boolean = false
	tab = 0
	translations: Translation[] = []
	language: any = this.languages[0]
	name: string = ""
	description: string = ""

	created() {
		this.refresh()
	}

	rules = {
		required: (input: string) => (input ? true : "Por favor, rellene este campo"),
		email: (input: string) => StringTool.validateEmail(input) ? true : `Email invalido`,
		postalCode: (input: string) => StringTool.validatePostalCode(input) ? true : `Codigo postal invalido`,
	}

	productName: Translation = new Translation()
	productDescription: Translation = new Translation()

	async refresh() {
		await ProductService.getProduct(this, Number(this.$route.params.productId))
		await this.getTranslations()
	}

	@Watch("language")
	async getTranslations() {
		if (this.language.value != "SPANISH") {
			await TranslationService.getTranslations(this, this.translations, EntityType.PRODUCT, this.language.value, Number(this.$route.params.productId))
		}
	}

	@Watch("translations")
	onTranslationsChanged() {

		if (this.translations.find(t => t.entityField == "name")) {
			this.productName = this.translations.find(t => t.entityField == "name")!
		} else {
			this.productName.translate = undefined
			this.productName.id = undefined
		}

		if (this.translations.find(t => t.entityField == "description")) {
			this.productDescription = this.translations.find(t => t.entityField == "description")!
		} else {
			this.productDescription.translate = undefined
			this.productDescription.id = undefined
		}
	}

	async saveTranslations() {
		for (const translation of [this.productName, this.productDescription]) {
			if (translation.translate != "" && translation.translate != undefined) {
				if (translation.id != undefined) {
					await TranslationService.patchTranslation(this, translation.id, translation)
				} else {
					await TranslationService.postTranslation(this, translation)
				}
			}
		}

		await this.refresh()
	}

	@Watch("language")
	onTranslationChanged() {
		this.productName.language = this.language.value
		this.productDescription.language = this.language.value
	}

}
