













































































































import {Component, Mixins, Prop, PropSync, Ref, Vue} from "vue-property-decorator";
import Product from "@/models/Product";
import {MultipleItem, SingleItem} from "@/handlers/interfaces/ContentUI";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import Video from "@/models/Video";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import ProductService from "@/services/ProductService";
import Handler from "@/handlers/Handler";
import VideoService from "@/services/VideoService";
import DistributorService from "@/services/user/DistributorService";
import SnackbarMixin from "@/mixins/SnackbarMixin";

@Component
export default class ProductEditTab extends Mixins(SnackbarMixin) {

    @Ref() readonly form!: HTMLFormElement
    @PropSync("product") readonly syncedProduct!: SingleItem<Product>
    lang: any = getModule(LangModule).lang
    loading: boolean = false
    brand: string = "Ninguna"
    videos: MultipleItem<Video> = { items: [], totalItems: 0 }

    created() { this.refresh() }

    async refresh() {
        await Handler.getItems(this, this.videos, () => VideoService.getVideos2(0, 10, null))
    }

    updateProduct() {
        if (this.form.validate()) {
            getModule(DialogModule).showDialog(new Dialog(
                this.lang.warning, this.lang.updateProductConfirmation, async () => {
                    await Handler.getItem(this, this.syncedProduct, () =>
                      ProductService.patchProduct2(this.syncedProduct.item.id!, this.syncedProduct.item)
                    )
                    await ProductService.patchProduct(this, this.syncedProduct.item, Number(this.$route.params.productId))
                }
            ))
        }
    }

    setActive() {
        getModule(DialogModule).showDialog(new Dialog(
            this.lang.warning,
            "¿Esta seguro de cambiar el estado del producto?",
            async () => await Handler.getItem(this, this.syncedProduct, () => {
                let response = ProductService.setEnabled(Number(this.$route.params.productId), !this.syncedProduct.item.active!!)
                this.makeToast("El estado del producto fue actualizado.")
                return response
            })
        ))
    }

}
